<template>
  <div class="page">
    <div class="box1">
      <div class="main_box">
        <div class="ttl">全场景：<br />劳务实名制解决方案</div>
        <div class="sub_ttl">
          规范劳务管理，规避用工风险，为工程建设保驾护航
        </div>
        <a
          class="btn"
          href="http://p.qiao.baidu.com/cps/chat?siteId=13160731&userId=27403999&siteToken=839057cc7afae96bcdd508339dc7a783"
          target="_blank"
        >
          咨询服务
        </a>
      </div>
    </div>
    <div class="box5">
      <div class="title">方案应用场景</div>
      <div class="main_box">
        <div class="row" flex="main:justify">
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/流程_1.jpg"
              alt="一、工人扫码入场"
            />
            <div class="ttl">一、工人扫码入场</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/流程_2.jpg"
              alt="二、签署电子劳动合同"
            />
            <div class="ttl">二、签署电子劳动合同</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/流程_3.jpg"
              alt="三、人脸识别考勤"
            />
            <div class="ttl">三、人脸识别考勤</div>
          </div>
        </div>
        <div class="row" flex="main:justify">
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/流程_4.jpg"
              alt="四、工资智能核算"
            />
            <div class="ttl">四、工资智能核算</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/流程_5.jpg"
              alt="五、手机确认工资单"
            />
            <div class="ttl">五、手机确认工资单</div>
          </div>
          <div class="col" flex-box="0">
            <img
              class="pic"
              src="@/assets/img/流程_6.jpg"
              alt="六、结清工资退场"
            />
            <div class="ttl">六、结清工资退场</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="title">为您有效规避劳务风险</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box2.swiper = sw)"
            @slideChange="() => (box2.crtIndex = box2.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box2.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box2.crtIndex === i ? 'z-crt' : '']"
              @click="box2.swiper.slideTo(i)"
              v-for="(item, i) in box2.slides"
              :key="i"
            />
          </div>
        </div>
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box2.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box2.slides"
            :key="idx"
            @click="box2.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box4">
      <div class="title">快速提升劳务管理效率</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box4.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box4.slides"
            :key="idx"
            @click="box4.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box4.swiper = sw)"
            @slideChange="() => (box4.crtIndex = box4.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box4.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box4.crtIndex === i ? 'z-crt' : '']"
              @click="box4.swiper.slideTo(i)"
              v-for="(item, i) in box4.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box6">
      <div class="title">全场景高效落地实名制考勤</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box6.swiper = sw)"
            @slideChange="() => (box6.crtIndex = box6.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box6.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box6.crtIndex === i ? 'z-crt' : '']"
              @click="box6.swiper.slideTo(i)"
              v-for="(item, i) in box6.slides"
              :key="i"
            />
          </div>
        </div>
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box6.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box6.slides"
            :key="idx"
            @click="box6.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box7">
      <div class="title">为您建立企业专属劳务资源库</div>
      <div class="main_box" flex="main:justify cross:center">
        <div class="row_tabs" flex-box="0">
          <div
            :class="['item', box7.crtIndex === idx ? 'z-crt' : '']"
            flex="dir:left"
            v-for="(s, idx) in box7.slides"
            :key="idx"
            @click="box7.swiper.slideTo(idx)"
          >
            <div class="num">{{ idx + 1 }}</div>
            <div>
              <div class="ttl">{{ s.ttl }}</div>
              <div class="desc">{{ s.desc }}</div>
            </div>
          </div>
        </div>
        <div class="phone_swp" flex-box="0">
          <swiper
            @ready="(sw) => (box7.swiper = sw)"
            @slideChange="() => (box7.crtIndex = box7.swiper.realIndex)"
            class="swp swiper-no-swiping"
            :options="{ autoplay: { delay: 4000 } }"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box7.slides"
              :key="idx"
            >
              <img class="pic" :src="s.pic" alt="s.ttl" />
            </swiper-slide>
          </swiper>
          <div class="pagination" flex="main:center">
            <div
              :class="['dot', box7.crtIndex === i ? 'z-crt' : '']"
              @click="box7.swiper.slideTo(i)"
              v-for="(item, i) in box7.slides"
              :key="i"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <div class="title">智能考勤设备</div>
      <div class="main_box">
        <div class="swp_wrap">
          <swiper
            @ready="(sw) => (box3.swiper = sw)"
            @slideChange="() => (box3.crtIndex = box3.swiper.realIndex)"
            class="swp swiper-no-swiping"
          >
            <swiper-slide
              class="sld"
              v-for="(s, idx) in box3.devices"
              :key="idx"
            >
              <div class="group">
                <div
                  class="case_item"
                  @click.stop="showDeviceDetail(c.id)"
                  v-for="c in s.list"
                  :key="c.id"
                >
                  <div
                    class="cover"
                    :style="{
                      backgroundImage: `url(${c.cover})`,
                    }"
                  ></div>
                  <div class="info">
                    <div class="ttl">{{ c.name }}</div>
                    <div class="desc">{{ c.desc }}</div>
                    <div class="more" flex="cross:center">
                      了解详情<img
                        class="i"
                        src="@/assets/img/ic_32_箭头@3x.png"
                        alt=">"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <div class="box8">
      <div class="title">劳务实名制国家政策</div>
      <div class="main_box" flex="main:justify">
        <div class="col c1" flex="dir:top main:center cross:center">
          <div class="info">
            <div class="ttl">劳务实名制政策</div>
            <div class="link">
              <a
                class="t"
                target="_blank"
                href="http://www.gov.cn/xinwen/2019-01/10/content_5356404.htm"
                >点击查看详情</a
              >
            </div>
          </div>
        </div>
        <div class="col c2" flex="dir:top main:center cross:center">
          <div class="info">
            <div class="ttl">农民工工资保障条例</div>
            <div class="link">
              <a
                class="t"
                target="_blank"
                href="http://www.gov.cn/zhengce/content/2020-01/07/content_5467278.htm"
                >点击查看详情</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="trial_bar">
      <div class="main_box" flex="main:justify cross:center">
        <div class="ttl">为您打造一套数智工程管理解决方案</div>
        <div class="btn" @click="showTrialModal">申请体验</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Footer from "@/components/Footer.vue";
import devices from "@/data/devices";

const baseUrl = `${process.env.BASE_URL}public/img/手机截图`;

const box2 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "电子合同",
      desc: "线上完成劳动合同签署、备案、云存档，高效合规",
      pic: `${baseUrl}/劳务实名制_1_1.png`,
    },
    {
      ttl: "人证合一",
      desc: "个人信息与人脸识别自动对接公安系统，消除用人风险",
      pic: `${baseUrl}/劳务实名制_1_2.png`,
    },
    {
      ttl: "核心信息",
      desc: "完整收集身份证、银行卡、技能证书、历史违规等",
      pic: `${baseUrl}/劳务实名制_1_3.png`,
    },
    {
      ttl: "政府实名制",
      desc: "支持高效对接政府实名制平台、云筑等权威平台",
      pic: `${baseUrl}/劳务实名制_1_4.png`,
    },
    {
      ttl: "企业黑名单",
      desc: "将严重违规工友拉入黑名单，防止进入公司其他项目",
      pic: `${baseUrl}/劳务实名制_1_5.png`,
    },
  ],
};

const box3 = {
  swiper: null,
  crtIndex: 0,
  devices: [
    {
      ttl: "智能考勤设备",
      list: [2, 1, 12, 0].map((n) => devices[n]),
    },
    {
      ttl: "施工设备",
      list: [6, 7, 9, 8].map((n) => devices[n]),
    },
    {
      ttl: "安全检测设备",
      list: [5, 10, 3, 4].map((n) => devices[n]),
    },
    {
      ttl: "其他硬件设备",
      list: [11, 13].map((n) => devices[n]),
    },
  ],
};

const box4 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "扫码可用",
      desc: "微信扫码即可用，无需下载，工友打卡无需排队等待",
      pic: `${baseUrl}/劳务实名制_2_1.png`,
    },
    {
      ttl: "智能打卡",
      desc: "AI人脸识别+活体检测，每天都能增加有效用工时间",
      pic: `${baseUrl}/劳务实名制_2_2.png`,
    },
    {
      ttl: "实时定位",
      desc: "离开施工范围立即预警提醒，后台实时统计离开时长",
      pic: `${baseUrl}/劳务实名制_2_3.png`,
    },
    {
      ttl: "智能分析",
      desc: "出勤数据智能分析，班组长再也不用去现场点人头",
      pic: `${baseUrl}/劳务实名制_2_4.png`,
    },
    {
      ttl: "一键导出",
      desc: "各类报表如工友花名册，考勤表，工资分配表等表单",
      pic: `${baseUrl}/劳务实名制_2_5.png`,
    },
  ],
};

const box6 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "手机考勤",
      desc: "AI人脸识别+活体检测，通过蓝牙或GPS定位即可",
      pic: `${baseUrl}/劳务实名制_3_1.png`,
    },
    {
      ttl: "硬件考勤",
      desc: "通过智能硬件，在封闭场景，手机与硬件互联互通",
      pic: `${baseUrl}/劳务实名制_3_2.png`,
    },
    {
      ttl: "离线考勤",
      desc: "特殊时期或场景出现无网或弱网可以用APP离线考勤",
      pic: `${baseUrl}/劳务实名制_3_3.png`,
    },
    {
      ttl: "协助考勤",
      desc: "无智能手机或手机在没电情况可以让工友协助考勤",
      pic: `${baseUrl}/劳务实名制_3_4.png`,
    },
    {
      ttl: "无感考勤",
      desc: "智能AI摄像头，自动抓拍入场离场人员，实现考勤",
      pic: `${baseUrl}/劳务实名制_3_5.png`,
    },
    {
      ttl: "海外考勤",
      desc: "强力支援海外建设人员，满足全球建设及考勤管理",
      pic: `${baseUrl}/劳务实名制_3_6.png`,
    },
  ],
};

const box7 = {
  swiper: null,
  crtIndex: 0,
  slides: [
    {
      ttl: "真实履历",
      desc: "如假包换：项目用工生成真实履历，用工轨迹一目了然",
      pic: `${baseUrl}/劳务实名制_4_1.png`,
    },
    {
      ttl: "分包库",
      desc: "攻坚拔寨：通过项目锤炼出一支能攻坚拔寨的分包队伍",
      pic: `${baseUrl}/劳务实名制_4_2.png`,
    },
    {
      ttl: "班组库",
      desc: "精挑细选：海量用工精挑细选出一批干部型的班组",
      pic: `${baseUrl}/劳务实名制_4_3.png`,
    },
    {
      ttl: "工友库",
      desc: "聚沙成海：智能实时留存，海量建设工友库",
      pic: `${baseUrl}/劳务实名制_4_4.png`,
    },
  ],
};

export default {
  components: {
    Footer,
  },
  data() {
    return {
      box2,
      box3,
      box4,
      box6,
      box7,
    };
  },
  methods: {
    ...mapActions(["showTrialModal", "showDeviceDetail"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";

.box1 {
  background: url("~@/assets/bg/banner 2.jpg") no-repeat center center;
  background-size: cover;
  height: 32rem;
  overflow: hidden;

  .ttl {
    margin-top: 13.8rem;
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.8rem;
    letter-spacing: 0.2rem;
  }

  .sub_ttl {
    margin-top: 1.1rem;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
  }

  .btn {
    margin-top: 3rem;
    display: block;
    width: 6.6rem;
    height: 2rem;
    background-color: #2574ff;
    border-radius: 0.4rem;
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: #fff;
    line-height: 2rem;

    &:hover {
      background-color: #045af2;
    }
  }
}

.box3 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .tabs {
    margin-top: 1.6rem;
    display: flex;
    justify-content: center;

    .item {
      width: 8rem;
      height: 2rem;
      line-height: 2rem;
      border: 1px solid #ddd;
      border-radius: 0.3rem;
      text-align: center;
      font-size: 0.9rem;
      color: #666;
      margin-right: 0.9rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        background-color: #2574ff;
        border-color: #2574ff;
        color: #fff;
        cursor: auto;
      }
    }
  }

  .main_box {
    .swp_wrap {
      position: relative;
      overflow: hidden;

      .swp {
        height: 25rem;
        margin: 0 auto;

        .group {
          padding-top: 3.3rem;
          overflow: hidden;
          width: $mainWidth + 3rem;
        }
      }
    }
  }

  .case_item {
    float: left;
    margin-right: 2.7rem;
    width: 15rem;
    cursor: pointer;
    transition: transform 0.3s;

    &:hover {
      transform: translate3d(0, -0.15rem, 0);
    }

    .cover {
      width: 100%;
      height: 15rem;
      background-size: cover;
    }

    .info {
      margin-top: 0.8rem;
      padding: 0 0.5rem;
      overflow: hidden;
    }

    .ttl {
      font-size: 0.9rem;
      color: #1a1a1a;
      line-height: 1.35rem;
    }

    .desc {
      margin-top: 0.35rem;
      font-size: 0.7rem;
      color: #858b96;
      line-height: 1.1rem;
      max-height: 2.5rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .more {
      margin-top: 0.6rem;
      font-size: 0.7rem;
      font-weight: bold;
      color: #0078f0;
      line-height: 1rem;

      .i {
        display: block;
        margin-left: 0.2rem;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
}

.box5 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0 7.85rem;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .row {
    margin-top: 3rem;
  }

  .col {
    .pic {
      display: block;
      width: 21.25rem;
      height: 14.2rem;
    }

    .ttl {
      margin-top: 1.35rem;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      color: #1a1a1a;
      line-height: 1.8rem;
    }
  }
}

.box2,
.box6 {
  overflow: hidden;
  background-color: #f0f1f6;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景.png") no-repeat center center;
    background-size: 100% 100%;
    padding-left: 5rem;
    box-sizing: border-box;
  }
}

.box4,
.box7 {
  overflow: hidden;
  background-color: #fff;
  padding: 7rem 0;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 7rem;
    width: 56.35rem;
    height: 31rem;
    background: url("~@/assets/bg/装饰背景2.png") no-repeat right center;
    background-size: auto 100%;
    padding-right: 4.5rem;
    box-sizing: border-box;
  }
}

.box8 {
  padding-bottom: 5rem;
  background-color: #f0f1f6;

  .title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #1a1a1a;
    line-height: 3rem;
  }

  .main_box {
    margin-top: 3.5rem;

    .col {
      flex-shrink: 0;
      position: relative;
      width: 33rem;
      height: 10.5rem;
      color: #fff;
      background-size: cover;
      background-position: center center;

      &.c1 {
        background-image: url("~@/assets/bg/劳务实名制政策.png");
      }

      &.c2 {
        background-image: url("~@/assets/bg/农名工工资保障条例.png");
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
      }

      .info {
        position: relative;
        z-index: 1;
      }

      .ttl {
        font-size: 1.8rem;
        font-weight: bold;
        color: #fff;
        line-height: 2.5rem;
      }

      .link {
        text-align: center;

        .t {
          font-size: 1rem;
          text-decoration: underline;
          color: #fff;
          line-height: 1.5rem;
        }
      }
    }
  }
}

.trial_bar {
  background-color: #f0f1f6;
  padding: 0 0 1.6rem;

  .main_box {
    background: url("~@/assets/bg/申请体验背景.png") no-repeat center center;
    background-size: cover;
    height: 7rem;
  }

  .ttl {
    margin-left: 4rem;
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
    line-height: 2.5rem;
    letter-spacing: 0.15rem;
  }

  .btn {
    margin-right: 4rem;
    width: 7rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #0078f0;
    line-height: 2.4rem;
    cursor: pointer;

    &:hover {
      background-color: #dce8ff;
    }
  }
}

.main_box {
  width: $mainWidth;
  margin: auto;
}

.row_tabs {
  width: 24rem;

  .item {
    margin-bottom: 2rem;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    &.z-crt {
      .num {
        opacity: 1;
      }

      .ttl {
        font-size: 1.2rem;
        line-height: 1.8rem;
        margin-top: 0;
      }
    }

    .num {
      width: 1.7rem;
      height: 1.7rem;
      line-height: 1.7rem;
      text-align: center;
      background-color: #0078f0;
      border-radius: 0.6rem;
      opacity: 0.2;
      font-size: 1rem;
      font-weight: bold;
      color: #fff;
      margin-right: 0.8rem;
    }

    .ttl {
      font-size: 0.9rem;
      font-weight: bold;
      color: #1a1a1a;
      line-height: 1.35rem;
      margin-top: 0.2rem;
    }

    .desc {
      margin-top: 0.4rem;
      font-size: 0.7rem;
      color: #9a9a9a;
      line-height: 1.1rem;
    }
  }
}

.phone_swp {
  position: relative;
  width: 14.9rem;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30.55rem;
    z-index: 1;
    background: url("~@/assets/bg/1手机边框.png") center center no-repeat;
    background-size: contain;
  }

  .swp {
    width: 13rem;
    height: 28.15rem;
    margin: 0.9rem auto 0;

    .pic {
      display: block;
      width: 100%;
    }
  }

  .pagination {
    margin-top: 1.8rem;

    .dot {
      width: 0.6rem;
      height: 0.15rem;
      opacity: 0.4;
      background-color: #2574ff;
      border-radius: 0.1rem;
      cursor: pointer;
      transition: opacity 0.3s;
      margin-right: 0.4rem;

      &:last-child {
        margin-right: 0;
      }

      &.z-crt {
        opacity: 1;
      }
    }
  }
}
</style>
